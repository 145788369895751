import { IPartner } from 'src/modules/partner/model/partner.model';
import { IExternalServices } from 'src/modules/shared/models/external-services.model';
import { ICountry } from 'src/modules/shared/models/shared.model';



export interface ICustomer {
    legacyId: number;
    id: string;
    name: string;
    email: string;
    vatId: string;
    phone: string;
    partnerLegacyId: number;
    partnerId: string;
    hidePriceMineLicenser: boolean;
    countryCode: ICountry['code'];
    address: ICustomerAddress;
    externalServices: IExternalServices;
    tags: string[];
    invoiceContactName: string;
    invoiceEmail: string;
    displayCurrency: string;
    invoiceCurrency: string;
    customerReference: string;
    partner?: IPartner;  // 'partner' and 'serviceClass' are additional properties added by the frontend
    serviceClass?: ICustomerServiceClass;  // (partner for the partnerObject data and serviceClass for showing externalServices health status)
}


export interface ICustomerAddress {
    streetName?: string;
    streetName2?: string;
    city?: string;
    region?: string;
    postalCode?: string;
    countryCode: ICountry['code'];
}


export interface ICustomerFilter {
    pageIndex: number;
    pageSize: number;
    filterCustomerName?: string;
    filterPartnerLegacyId?: any;
    filterPartnerId?: string;
}



export interface ICustomerServiceClass {
    [key: string]: string;
}


export interface IRole{
    id: number;
    name: string;
    protected: boolean;
    permissions: number[];
}

export enum PermissionNode {
  isAdmin = 0,
  isPartner = 1,
  developer = 2,
  partnerManageCustomCategories = 3,
  partnerManageCustomProducts = 4,
  partnerManageOwnLogins = 5,
  manageMicrosoftSeats = 6,
  superAdmin = 7,
  betaPage = 8,
  customPdfTemplate = 9,
  canResetMFA = 10,
  endCustomerAccounts = 11,
  dropboxManagement = 12,
  infrastructureManagement = 13,
  acronisManagement = 14,
}

